<template>
  <div>
    <a-row>
      <a-col   :md="{ span: 2, offset: 3 }" :lg="{span: 12, offset: 6}" :xl="{span: 12, offset: 8}">
        <flightplans-selector style="margin: 0px 0px 30px 15px;" />
      </a-col>
    </a-row>
    <a-row>
      <MissionMapsList />
    </a-row>
  </div>
</template>

<script>

import FlightplansSelector from '../FlightplansSelector'
import MissionMapsList from './MissionMapsList'

export default {
  name: 'missionMapsEdit',
  components: {
    FlightplansSelector,
    MissionMapsList
  }
}
</script>
