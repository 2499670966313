<template>
  <a-row
    type="flex"
    justify="start"
  >
    <a-col :offset="6">
      <div class="gmap-button">
        <a-button  
          type="primary"
          icon="select"
          @click="eventBus.$emit('resetRectangle')"
        >
        {{ $t('missionMaps.recenterSelector') }}
        </a-button>
      </div>
    </a-col>
  </a-row>
</template>

<script>

import Vue from 'vue'
import { Button, Row, Col, Icon } from 'ant-design-vue'

Vue.use(Button)
Vue.use(Row)
Vue.use(Col)
Vue.use(Icon)

export default {
  name: 'missionMapsGmapButtons',
  props: {
    eventBus: {
        type: Object
    }
  }
}
</script>

<style lang="less" scoped>
  .gmap-button {
    .ant-btn-primary {
      background-color: #0AACF7;
      border-color: #84afd7;
    }
  }
</style>