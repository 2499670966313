<script>

import Gmaps from '../../Gmaps'
import { isFPManager } from "../../../utils/userAuth"

let rectangle = null

export default {
  name: 'mission-maps-bounds',
  mixins: [Gmaps],
  props: {
    eventBus: {
      type: Object
    },
    missionMap: {
      type: Object
    }
  },
  created () {
    this.eventBus.$on('resetRectangle', () => {
      const center = this.map.getCenter()
      const newBounds = new this.google.maps.LatLngBounds(
        {lat: center.lat() - 0.001, lng: center.lng() - 0.001},
        {lat: center.lat() + 0.001, lng: center.lng() + 0.001}
      )

      rectangle.setBounds(newBounds)
    })
  },
  async mounted () {
    await this.mapInitPromise

    const latCenter = (this.missionMap.northEastLat + this.missionMap.southWestLat) / 2
    const lngCenter = (this.missionMap.northEastLng + this.missionMap.southWestLng) / 2

    this.map.setCenter({
        lat: latCenter,
        lng: lngCenter
    })
    this.map.setZoom(this.missionMap.zoomLevel)
    this.map.addListener('zoom_changed', this.onZoomLevelChanged)

    rectangle = new this.google.maps.Rectangle({
      strokeColor: '#0AACF7',
      strokeOpacity: 0.8,
      strokeWeight: 1.5,
      fillColor: '#0AACF7',
      fillOpacity: 0.20,
      map: this.map,
      editable: isFPManager(),
      draggable: isFPManager(),
      bounds: new this.google.maps.LatLngBounds(
        {lat: this.missionMap.southWestLat, lng: this.missionMap.southWestLng},
        {lat: this.missionMap.northEastLat, lng: this.missionMap.northEastLng},
      )
    })
    rectangle.addListener('bounds_changed', this.onRectangleBoundsChanged)
  },
  methods: {
    onRectangleBoundsChanged () {
      const bounds = rectangle.getBounds()
      const NE = bounds.getNorthEast()
      const SW = bounds.getSouthWest()

      this.$emit('bounds-changed', {
        northEastLat: NE.lat(),
        northEastLng: NE.lng(),
        southWestLat: SW.lat(),
        southWestLng: SW.lng()
      })
    },
    onZoomLevelChanged () {
      this.$emit('zoom-level-changed', this.map.getZoom())
    }
  }
}
</script>